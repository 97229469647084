import React from "react";
import {Helmet} from "react-helmet";

export default function ResearchAndDevelopmentPage() {

    return (
        <Helmet>
            <meta name="description" content="Новые проекты и исследования, которые ведутся R&D специалистами компании «РУССЭЛЛ»: описание, стадии, принципы работы." />
        </Helmet>
    );

}
